import React from 'react';

import { Page } from 'src/components';

const Legal = () => (
  <Page title="Legal">
    <Page.Main className="container max-w-screen-lg py-32 space-y-6">
      <div className="flex items-center justify-center">
        <h3 className="font-bold">Health Security Product Agreement</h3>
      </div>
      <p>
        This Health Security Product Agreement (the “Agreement”) is entered into by and between
        Sharecare, Inc. (“Sharecare”) and the customer identified on the order form (“Order”)
        accompanying this Agreement (“Health Security Customer”) (each of which may be referred to
        hereinafter individually as a “Party” and collectively as the “Parties”) effective as of the
        date of the Health Security Customer’s acceptance of this Agreement. The Order is
        incorporated by reference into this Agreement.
        <br />
        <br />
        By Health Security Customer Agreement accepting this Agreement as part of its Order, this
        Agreement will become binding on the Parties. Health Security Customer’s affirmative
        selection in connection with its Order shall constitute an electronic signature. Signatures
        transmitted by facsimile, or by .pdf or similar transmission, will be deemed to be original
        signatures for any purpose whatsoever.
        <br />
        <br />
        To the extent Health Security Customer is contracting on behalf of its affiliates,
        subsidiaries, franchisees or other organization identified on an Order (each an “Affiliated
        Organization”), Health Security Customer represents and warrants that it is authorized to
        bind such Affiliated Organization and will be responsible for such Affiliated Organization’s
        obligations under this Agreement. The term “Health Security Customer” as used in this
        Agreement shall include each Affiliated Organization accessing the Sharecare Services
        hereunder.
        <br />
        <br />
        The Parties agree as follows:
      </p>
      <ul className="space-y-12">
        <li className="space-y-6">
          <h5 className="font-bold">1. Scope of Services; License.</h5>
          <p className="pl-2">
            a. Sharecare shall provide Health Security Customer with proactive and reactive digital
            tools to minimize the danger and impact of acute health events and support health and
            wellbeing. Specifically, this service provides a digital suite of products utilized by
            Health Security Customer to help its employees return to the workplace safely while
            continuing to support their health and wellbeing and offer its customers security in
            knowing that precautions have been taken to allow them to safely visit Health Security
            Customer’s facilities. Sharecare Health Security provides an overarching framework that
            delivers real-time transparency through the verification process, and continued
            interoperability through the compliance and screening processes, giving Health Security
            Customer leadership full control and oversight over the safety of the facility and
            employees, and providing customers the confidence through trusted verification. The
            foregoing shall be referred to herein as the “Sharecare Services” or the “Service(s)”,
            which Services shall include but not be limited to delivery of a digital program
            centered on health security (the “Health Security Product”). Health Security Customer
            shall have access to one or a combination of the following features of the Service, in
            each case if purchased via an Order:
          </p>

          <p className="pl-4">i. Facility Verification</p>
          <p className="pl-8">
            A. Sharecare will provide to Health Security Customer a tech-enabled, comprehensive and
            interactive, checklist of facility-related requirements for safe reopening and ongoing
            operation. The checklist may include access control, physical distancing, hygiene and
            disinfection, administrative, access control and facility equipment verification
            guidelines. Sharecare shall provide expert verified recommendations and best practice
            guidelines for Health Security Customer-developed standard operating procedures. When
            Health Security Customer is ready to verify a facility, it will begin the
            self-verification process by initiating the Sharecare Health Security digital checklist.
            The intelligent chatbot will guide Health Security Customer personnel through the
            checklist and self-verification process. Health Security Customer will have access to an
            interactive digital dashboard to view aggregated data across the facility verification
            process and compliance status.
          </p>
          <p className="pl-8">
            B. Virtual Badge. Upon the successful completion of the Facility Verification process
            and Health Security Customer confirmation that the required/recommended measures are in
            place at the facility, and that the standard operating procedures have been implemented,
            Sharecare will make available a Seal of Certification that can be placed on Health
            Security Customer’s webpage. This virtual badge, once placed on Health Security
            Customer’s webpage, can provide information to page visitors about the Sharecare Health
            Security verification process as well as the rigorous safety and operating procedures in
            place at Health Security Customer’s facility. Rules apply to use of Sharecare and
            Sharecare Health Security branding and logos, and to regular re-certification
            requirements. The validity and availability of the badge are subject to on-going
            compliance and verification processes of the Health Security Product. If a facility
            falls out of compliance and does not cure the Health Security gaps within the required
            period, the badge status may change, or it may be withheld until such gaps have been
            closed. Health Security Customer shall be responsible for adhering to all of the
            Facility Verification rules and requirements, including but not limited to instructions
            on the display of the virtual badge and the mandatory inclusion of disclaimers.
          </p>

          <p className="pl-4">
            ii. Employee Safety and Readiness. Sharecare will provide Health Security Customer with
            digital tools to enable its workforce to return to work safely, manage communications,
            and direct employees to resources necessary for them to manage their health and
            wellbeing. Sharecare will provide:
          </p>
          <p className="pl-8">
            A. Readiness Assessment: Employees will be prompted to obtain digital accreditation to
            confirm that each employee meets standards to safely return to work. Each employee shall
            be directed to participate in a clinically validated daily screening and/or assessment
            through Sharecare’s Health Security Readiness screener. The screening will help to
            determine if the employees have the tools, protections and information needed for them
            to engage in their daily work and attest to being free from COVID-19 related symptoms or
            symptoms related to other acute health conditions that would impair their ability to
            report to work. Optionally, the employee may be asked to confirm vaccination, anti-body
            test, antigen test and/or other health test status.
          </p>
          <p className="pl-8">
            B. Daily Work Pass. After completing the daily screening and/or assessment, each
            employee will be validated and given a digital daily work pass, which can be used to
            permit access to the facility.
          </p>
          <p className="pl-8">
            C. Access to Virtual Physicians and/or Testing. Upon Health Security Customer’s
            election, Sharecare shall make telemedicine services and COVID-19 testing services
            available to Health Security Customer employees. Health Security Customer employees will
            have the ability to schedule a telemedicine visit with a physician or schedule a
            COVID-19 test directly through Sharecare’s interactive platform. The Health Security
            Customer may use a telemedicine and/or a testing provider of their choice.
          </p>

          <p className="pl-4">
            iii. Guest/Consumer Services. Sharecare will facilitate the screening and guiding of
            consumer and guest users based on their COVID-19 symptoms, exposure and risk group
            through the use of tools made available to Health Security Customer. Sharecare will
            provide an interactive concierge chatbot to screen consumers and guests for COVID-19
            symptoms or other acute health condition status, and guide them accordingly. Optionally,
            the user may be asked to confirm vaccination, anti-body test, antigen test and/or other
            health test status.
          </p>
          <p className="pl-8">
            A. The Health Security Concierge guides users though a set of questions to help
            determine if the user may need to be vaccinated or tested for critical illness. If
            applicable, the user shall be directed to a designated phone number or URL (e.g. online
            self-scheduler) provided and operated by the Health Security Customer or a 3rd party
            vendor contracted by Health Security Customer. If the user doesn&apos;t require
            vaccination or testing, the screener service can provide specific messaging and/or a
            different phone number / URL, provided and operated by the Health Security Customer
            (optional).
          </p>
          <p className="pl-8">
            B. If Health Security Customer chooses to use a custom configuration of Sharecare’s
            standard product, the decision logic to determine vaccination or test guidance and
            messaging requirements will be provided by the Health Security Customer and reviewed by
            Sharecare’s clinical experts. The delivery date for the screening service will be
            confirmed by Sharecare after mutual sign-off on requirements. Updates to the decision
            logic will be made as required by health authorities (e.g. CDC). The default number of
            screening questions used to determine decision logic shall not exceed 15 questions. The
            screening questions and logic can be mutually agreed to be extended.
          </p>
          <p className="pl-8">
            C. This service will be delivered to Health Security Customer in the form of a bot code
            package for embedding on Health Security Customer’s own webpages or as designated by
            Health Security Customer.
          </p>

          <p className="pl-4">
            iv. Reporting Dashboard. Depending on the scope of services Health Security Customer
            purchases, Health Security Customer will have access to a Health Security Dashboard to
            monitor Facility Certification status, or to monitor the status of the screeners using
            aggregated and unidentifiable information. Sharecare will provide Health Security
            Customer with two (2) licenses for access to daily reporting and analytics for its
            selected services.
          </p>

          <p className="pl-2">
            b. License. Sharecare grants Health Security Customer a limited, revocable,
            non-exclusive license to use those components of the Health Security Product and
            Sharecare Services purchased by Health Security Customer via an Order, for the sole
            purpose of participating in the Sharecare Health Security program (the “License”). The
            License shall extend only to Health Security Customer and any Affiliated Organization
            enumerated in an Order.
          </p>
        </li>
        <li className="space-y-6">
          <h5 className="font-bold">2. Authorized Use.</h5>
          <p className="pl-2">
            a. Use of Sharecare Services shall be subject to the then-current Sharecare Privacy
            Policy and Terms applicable to the Sharecare Services as published at
            <a
              href="https://www.sharecare.com/static/terms"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              {` https://www.sharecare.com/static/terms `}
            </a>
            or otherwise presented to Users as part of the Sharecare Services (“Sharecare Service
            Terms”). Depending on the type of User or other individual accessing the Sharecare
            Service, the Sharecare Service Terms may include the
            <a
              href="https://sharecarebot.azurewebsites.net/legal.html"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              {` Health Security Verification: End User Disclaimer `}
            </a>
            , the
            <a
              href="https://healthsecurity.sharecare.com/facility-disclaimer.html"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              {` Facility Consent/Disclaimer `}
            </a>
            and/or the
            <a
              href="https://sharecarebot.azurewebsites.net/readiness/legal.html"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              {` Screener Consent. `}
            </a>
            .
          </p>
          <p className="pl-2">
            b. Each employee, agent, assign, representative, independent contractor, customer or
            other person or entity authorized by Health Security Customer to access or use the
            Sharecare Services shall be referred to herein as a &quot;User.&quot; In the standard
            configuration of the Health Security products users are not required to establish an
            account with Sharecare, with the exception of facility verification users entering
            facility data on behalf of Health Security Customer.
          </p>
          <p className="pl-2">
            c. In the event of a conflict between the terms of this Agreement and the Sharecare
            Service Terms, the terms of this Agreement shall prevail.
          </p>
        </li>
        <li>
          <h5 className="font-bold">3. Term and Termination.</h5>
          <p>
            This Agreement shall remain in effect for as long as any Order between the Parties is in
            effect. The term of each Order shall be set forth in the Order. In the event no term is
            specified in an Order, the term of such Order shall be one (1) year. The term of each
            Order shall automatically renew for periods of one (1) year unless either Party notifies
            the other Party in writing of its intent not to renew the Order no less than thirty (30)
            days before the expiration thereof. Each Party shall have the right to terminate the
            Agreement or any Orders upon a material breach by the other Party which has not been
            cured within thirty (30) days, such cure period to commence upon the breaching Party’s
            receipt of written notice from the non-breaching Party setting forth an alleged event of
            material breach.
          </p>
        </li>
        <li className="space-y-6">
          <h5 className="font-bold">4. Fees, Expenses and Payment.</h5>

          <p className="pl-2">
            a. Health Security Customer shall pay Sharecare the fees in the amount and manner set
            forth in each Order (“Fees”).
          </p>

          <p className="pl-2">
            b. Health Security Customer shall not be liable to Sharecare for any expenses paid or
            incurred by Sharecare unless otherwise agreed to and approved in advance in writing by
            Health Security Customer (the “Expenses”). Any such Expenses shall be incurred only in
            connection with the project specified in an applicable Order and must fully conform to
            Health Security Customer’s then-current expense policies, as communicated from time to
            time.
          </p>

          <p className="pl-2">
            c. Unless otherwise specified in an applicable Order, Health Security Customer shall
            make all payments within thirty (30) days of receipt of an invoice from Sharecare. The
            invoice shall include supporting documentation for any Expenses. In the event Health
            Security Customer disputes any charges invoiced by Sharecare, Health Security Customer
            shall pay all undisputed amounts in the manner set forth in this Section, and
            concurrently provide written notice to Sharecare, including the appropriate
            documentation supporting Health Security Customer’s claim for disputed charges, by the
            invoice due date. Each Party agrees that it will meet and negotiate in good faith to
            resolve any dispute that may arise between them with respect to this Agreement and any
            related Order, if applicable. Each Party will provide to the other all reasonably
            requested information as is relevant to the resolution of the dispute.
          </p>

          <p className="pl-2">
            d. If payment for any invoiced amount is not received by Sharecare by the invoice due
            date, then without limiting Sharecare’s other available rights or remedies, those
            invoiced charges may accrue interest at the rate of 1.0% of the outstanding balance per
            month, or the maximum rate permitted by law, whichever is lower.
          </p>

          <p className="pl-2">
            e. If any charge owing by Health Security Customer is thirty (30) days or more overdue,
            Sharecare may, without limiting its other rights and remedies, suspend the Services for
            which such charges are owing until such amounts are paid in full, provided that
            Sharecare has given Health Security Customer at least ten (10) days’ prior written
            notice that its account is overdue in accordance with the “Notices” section below.
          </p>

          <p className="pl-2">
            f. Sharecare will not exercise its rights under Section 4(d) or 4(e) if Health Security
            Customer is disputing the applicable charges reasonably and in good faith in the manner
            set forth in Section 4(c) and is actively cooperating to resolve the dispute.
          </p>

          <p className="pl-2">
            g. All amounts payable by Health Security Customer under this Agreement are exclusive of
            any applicable taxes, levies, duties, or similar governmental assessments of any nature
            (including value-added, sales, and use taxes, but excluding withholding taxes and taxes
            based on Sharecare’s income, property, or employees) (“Taxes”) that may arise in
            connection with Health Security Customer’s purchases under this Agreement. If any such
            Taxes arise, Health Security Customer will pay such Taxes in addition to all other
            amounts payable under this Agreement, unless Health Security Customer provides Sharecare
            with a valid tax exemption certificate or other documentary proof, issued by an
            appropriate taxing authority, that no tax should be charged. If Health Security Customer
            is required by law to withhold any Taxes from its payments to Sharecare, Health Security
            Customer must provide with an official tax receipt or other appropriate documentation to
            support such payments.
          </p>
        </li>
        <li>
          <h5 className="font-bold">5. Performance.</h5>
          <p>
            Sharecare shall perform its obligations hereunder in a workmanlike and professional
            manner.
          </p>
        </li>
        <li className="space-y-6">
          <h5 className="font-bold">6. Sharecare Responsibilities.</h5>
          <p className="pl-2">
            a. In addition to all other obligations set forth in this Agreement, Sharecare shall:
            (i) provide the Services in accordance with this Agreement; (ii) perform all support
            obligations and abide by the service levels as set forth in the applicable Order; (iii)
            comply with Sharecare’s security policy and additional security requirements as set
            forth in this Agreement or any supplemental agreement between the Parties; (iv) abide by
            Sharecare’s Business Continuity/Disaster Recovery Plan; and (v) provide the Services in
            compliance with all applicable laws and government regulations, provided that where
            Sharecare Services are delivered to employees of Health Security Customer, Sharecare
            will not be responsible for complying with employment-related laws and/or regulations as
            if Sharecare were a co-employer; Health Security Customer shall advise Sharecare of any
            actions necessary to assist Health Security Customer with complying with
            employment-related laws and regulations.
          </p>
          <p className="pl-2">
            b. Sharecare shall be responsible for the acts and omissions of its employees, agents,
            contractors, and subcontractors in the same manner and to the same extent as if
            performed by Sharecare.
          </p>
        </li>
        <li className="space-y-6">
          <h5 className="font-bold">7. Health Security Customer Responsibilities.</h5>
          <p className="pl-2">
            a. In addition to all other obligations set forth in this Agreement, Health Security
            Customer shall: (i) be responsible for Users’ compliance with this Agreement, Sharecare
            Service Terms and Orders; (ii) use commercially reasonable efforts to prevent
            unauthorized access to or use of the Services, and notify Sharecare promptly of any such
            unauthorized access or use; (iii) use the Services only in accordance with applicable
            laws and government regulations; and (iv) be responsible for the accuracy, quality and
            legality of its data and the means by which Health Security Customer acquired its data.
          </p>
          <p className="pl-2">
            b. Health Security Customer shall not intentionally and knowingly: (i) make the Services
            available to, or use any Service for the benefit of, anyone other than Health Security
            Customer or Users; (ii) use the Services to store or transmit false, infringing,
            libelous, or otherwise unlawful or tortious material; (iii) use the Services to store or
            transmit material in violation of third party privacy rights; (iv) transmit malicious
            software or copy, translate, create a derivative work of, reverse engineer, reverse
            assemble, disassemble, or decompile the Health Security Product or any part thereof or
            otherwise attempt to discover any source code or modify the Health Security Product; (v)
            interfere with or disrupt the integrity or performance of the Services or third-party
            data contained therein; or (vi) attempt to gain unauthorized access to the Services or
            their related systems or networks; (vii) sell, resell, license, sublicense, distribute,
            make available, rent or lease any Service, or include any Service in a service bureau or
            outsourcing offering.
          </p>
          <p className="pl-2">
            c. Health Security Customer’s or a User’s intentional violation of the foregoing, or any
            use of the Services in breach of this Agreement or an Order, by Health Security Customer
            or Users that in Sharecare’s judgment imminently threatens the security, integrity or
            availability of Sharecare’s Services, may result in Sharecare’s immediate suspension of
            the Services. Sharecare will use commercially reasonable efforts under the circumstances
            to provide Health Security Customer with an opportunity to remedy such violation or
            threat prior to any such suspension.
          </p>
        </li>
        <li className="space-y-6">
          <h5 className="font-bold">8. Ownership of Materials.</h5>
          <p className="pl-2">
            a. As between Health Security Customer and Sharecare, Sharecare shall own all right,
            title and interest in and to the Health Security Product and other Sharecare software
            and Services, including, without limitation, all associated intellectual property rights
            throughout the world.
          </p>

          <p className="pl-2">
            b. No ownership rights in Health Security Customer-provided materials that predate the
            Agreement are transferred to Sharecare. Sharecare is granted a limited license to use
            materials provided by Health Security Customer solely in the performance of this
            Agreement and not for Sharecare’s or any third party’s benefit.
          </p>

          <p className="pl-2">
            c. Nothing in this Agreement limits that ability of Sharecare to obtain authorizations
            from end users of the Services, including, without limitation, the Users, with respect
            to data of such users.
          </p>

          <p className="pl-2">
            d. The Health Security Products are designed to protect the privacy of individual users.
            By default, Health Security Products can be used anonymously, unless elected otherwise
            by the user or the Health Security customer. If required for compliance, operational or
            regulatory purposes, the products is designed to collect as little data concerning
            individual users (&quot;User Data&qiot;) as possible. With regard to the employee or
            customer screener elements of the Health Security Product, Sharecare provides optional
            authentication methods that do not rely on personally identifiable information. All User
            Data and data related to use of the Service by Users shall be governed by the applicable
            Sharecare Service Terms.
          </p>

          <p className="pl-2">
            e. With regard to User Data, Sharecare shall have the right to: (i) retain transactional
            and derivative data; (ii) share User Data with third parties in accordance with the
            applicable Sharecare Service Terms and applicable law; and (iii) remove/delete User Data
            upon termination by the User of a Sharecare Service in compliance with regulatory
            requirements and the applicable Sharecare Service Terms.
          </p>

          <p className="pl-2">
            f. Health Security Customer shall not attempt to re-identify de-identified User Data or
            otherwise access identifiable User Data unless otherwise agreed in writing by the
            Parties, or attempt to use data received from Sharecare, for employment purposes such as
            hiring, firing, commencement of an adverse action or other data usage not permitted by
            law.
          </p>
          <p className="pl-2">
            g. The Parties acknowledge that in providing the Sharecare Service pursuant to this
            Agreement, Sharecare may have access to certain personally identifiable information
            provided by Health Security Customer and/or Users and/or third-party payors or plan
            administrators, which information may be subject to applicable international, federal,
            state and local data privacy and security laws and regulations (“Health Security
            Customer Protected Data”). Where required under applicable data privacy laws, the
            Parties will enter into such additional contracts as may be required under such laws,
            such as a Business Associate Agreement if applicable and required by the U.S. Health
            Insurance Portability and Accountability Act (“HIPAA”) and/or a Data Processing
            Agreement if applicable and required by the EU General Data Processing Regulation
            (“GDPR”) (each a “Data Privacy Agreement”). Any such Data Privacy Agreement, when
            entered into by the Parties, shall be governed by this Agreement and shall be
            incorporated by reference herein.
          </p>
        </li>
        <li className="space-y-6">
          <h5 className="font-bold">9. Confidential Information.</h5>
          <p>
            During the course of this relationship, it may be necessary or convenient for a Party to
            divulge Confidential Information (as herein defined) to the other Party. The following
            shall apply:
          </p>
          <p className="pl-2">
            a. The term “Confidential Information” means all non-public information that: (i) either
            Party designates as being confidential information in connection with the disclosure of
            such information; or (ii) is of a sensitive or proprietary nature, including, without
            limitation, negotiations in progress, terms of agreements, financial data, customer
            lists, advertising, marketing and promotional plans, and business partner lists,
            including, but not limited to, trade secrets.
          </p>
          <p className="pl-2">
            b. Confidential Information shall not include any information that (i) is at the time of
            disclosure or subsequently becomes publicly available without a Party’s breach of any
            obligations owed to the other Party; (ii) becomes known to a Party prior to disclosure
            of such information to a Party; (iii) becomes or became known to a Party without a
            breach of an obligation of confidentiality owed to the other Party; or (iv) is
            independently developed by a Party.
          </p>
          <p className="pl-2">
            c. The receiving Party shall retain in strict confidence all of the disclosing Party’s
            Confidential Information during the term of this Agreement and for three (3) years
            thereafter. Notwithstanding the foregoing, the receiving Party shall maintain the
            confidentiality of any trade secrets for so long as such Confidential Information is
            deemed a trade secret under applicable law.
          </p>
          <p className="pl-2">
            d. Notwithstanding the foregoing restrictions, the receiving Party may use and disclose
            any Confidential Information to the extent required by an order of any court or other
            governmental authority, but in each case only after the disclosing Party has been so
            notified and has had the opportunity, if possible, to seek and obtain reasonable
            protection for such information in connection with such disclosure.
          </p>
          <p className="pl-2">
            e. All Confidential Information shall remain the exclusive property of the disclosing
            Party and no license or similar rights of any kind shall be or be deemed to have been
            created or implied by the Agreement, except as otherwise expressly set forth herein.
          </p>
          <p className="pl-2">
            f. The provisions of this Section shall survive and be enforceable beyond the
            termination or completion of the Agreement for the period set forth in this Section.
          </p>
        </li>
        <li>
          <h5 className="font-bold">10. Warranties.</h5>
          <p>
            During an applicable subscription term: (a) Sharecare will not materially decrease the
            overall security of the Services, (b) the Services will perform materially in accordance
            with the applicable documentation, and (c) Sharecare will not materially decrease the
            overall functionality of the Services.
          </p>
        </li>
        <li className="space-y-6">
          <h5 className="font-bold">11. Disclaimer of Warranties.</h5>
          <p className="pl-2">
            a. Sharecare does not warrant that any website or any Service will meet Health Security
            Customer’s requirements or that the operation of the websites by Sharecare (or any of
            its subcontractors or agents) will be uninterrupted or error-free. Except as expressly
            set forth herein and/or in any applicable Order, Sharecare provides the Services on an
            “as is” and “as available” basis without warranties of any kind.
          </p>

          <p className="pl-2">
            b. OTHER THAN AS EXPRESSLY SET FORTH IN THE AGREEMENT, SHARECARE DOES NOT MAKE ANY
            EXPRESS OR IMPLIED WARRANTIES, CONDITIONS, OR REPRESENTATIONS, OR IMPLIED INDEMNITIES,
            TO HEALTH SECURITY CUSTOMER, ANY OF ITS AFFILIATES OR ANY OTHER PARTY WITH RESPECT TO
            THE SCOPE OF WORK AND/OR SERVICES PROVIDED UNDER THE AGREEMENT, WHETHER ORAL OR WRITTEN,
            EXPRESS, IMPLIED OR STATUTORY. WITHOUT LIMITING THE FOREGOING, ANY IMPLIED WARRANTY OR
            CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE ARE
            EXPRESSLY EXCLUDED AND DISCLAIMED. SHARECARE DOES NOT WARRANT OR GUARANTEE A MEDICAL
            COST SAVINGS OR A RETURN ON INVESTMENT OF SERVICES PROVIDED UNDER THIS AGREEMENT.
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SHARECARE DOES NOT REPRESENT OR
            WARRANT THAT THE SHARECARE SERVICES OR HEALTH SECURITY PRODUCT WILL ELIMINATE THE RISK
            OR SPREAD OF COVID-19 INFECTION WITHIN AND AMONG HEALTH SECURITY CUSTOMER’S FACILITIES,
            WORKPLACES, EMPLOYEE POPULATION OR CUSTOMERS.
          </p>

          <p className="pl-2">
            c. Except as otherwise specifically set forth herein, Sharecare’s sole obligation and
            Health Security Customer’s sole remedy, in the event Sharecare’s technology, such as
            websites, applications, or web portals, is unavailable or is not functioning properly,
            shall be re-performance of the affected Services.
          </p>
        </li>
        <li>
          <h5 className="font-bold">12. Indemnification by Sharecare.</h5>
          <p>
            Sharecare will defend Health Security Customer, including its officers, directors,
            employees and agents (collectively, the &quot;Health Security Customer Indemnified
            Parties&quot;), against any claim, demand, suit or proceeding made or brought against
            Health Security Customer Indemnified Parties by a third party (i) alleging that any
            Service infringes or misappropriates such third party’s intellectual property rights or
            (ii) based on Sharecare’s gross negligence or willful misconduct (a “Claim Against
            Health Security Customer”), and will indemnify Health Security Customer Indemnified
            Parties from any damages, attorney fees and costs finally awarded against Health
            Security Customer Indemnified Parties as a result of, or for amounts paid by Health
            Security Customer Indemnified Parties under a settlement approved by Sharecare in
            writing of, a Claim Against Health Security Customer, provided that Health Security
            Customer: (a) promptly gives Sharecare written notice of the Claim Against Health
            Security Customer, (b) gives Sharecare sole control of the defense and settlement of the
            Claim Against Health Security Customer (except that Sharecare may not settle any Claim
            Against Health Security Customer unless it unconditionally releases Health Security
            Customer Indemnified Parties of all liability), and (c) gives Sharecare all reasonable
            assistance, at Sharecare’s expense. The failure to comply with clause (a) of the
            immediately preceding sentence shall not affect Sharecare’s obligation to provide
            indemnification pursuant to this Section except to the extent such failure has
            materially adversely affected its ability to defend such Claim Against Health Security
            Customer, and notwithstanding clause (b) of the immediately preceding sentence, Health
            Security Customer shall have the right, at its own expense, to employ separate counsel
            in any such action, to observe the proceedings and, at Health Security Customer’s
            request, Sharecare will keep such counsel reasonably informed of such proceedings. If
            Sharecare receives information about an infringement or misappropriation claim related
            to a Service, Sharecare may, in its discretion and at no cost to Health Security
            Customer, (i) modify the Services so that they are no longer claimed to infringe or
            misappropriate, without breaching Sharecare’s warranties under “Sharecare Warranties”
            above, (ii) obtain a license for Health Security Customer’s continued use of that
            Service in accordance with this Agreement, or (iii) terminate Health Security Customer’s
            subscriptions for that Service upon 30 days’ written notice, to the extent that
            Sharecare is terminating such subscriptions of its similarly-situated customers
            generally, and refund Health Security Customer any prepaid fees covering the remainder
            of the term of the terminated subscriptions.
          </p>
        </li>
        <li>
          <h5 className="font-bold">13. Indemnification by Health Security Customer.</h5>
          <p>
            Health Security Customer will defend Sharecare, including its officers, directors,
            employees, agents, subsidiaries and affiliates (collectively &quot;Sharecare Indemnified
            Parties&quot;), against any claim, demand, suit or proceeding made or brought against
            Sharecare Indemnified Parties by a third party (i) alleging that any Health Security
            Customer Data infringes or misappropriates such third party’s intellectual property
            rights, (ii) arising from Health Security Customer’s or its Users’ use of the Services
            in breach of the Agreement, Sharecare Service Terms, Health Security Product rules and
            guidelines, an Order or applicable law, (iii) arising from misrepresentations or false
            information provided by Users in connection with the Health Security Product, or (iv) in
            any way related to the health and safety of persons, including but not limited to Users,
            occupying the premises of Health Security Customer except to the extent caused by the
            gross negligence or willful misconduct of Sharecare (each a “Claim Against Sharecare”),
            and will indemnify Sharecare Indemnified Parties from any damages, attorney fees and
            costs finally awarded against Sharecare Indemnified Parties as a result of, or for any
            amounts paid by Sharecare Indemnified Parties under a settlement approved by Health
            Security Customer in writing of, a Claim Against Sharecare, provided that Sharecare: (a)
            promptly gives Health Security Customer written notice of the Claim Against Sharecare,
            (b) gives Health Security Customer sole control of the defense and settlement of the
            Claim Against Sharecare (except that Health Security Customer may not settle any Claim
            Against Sharecare unless it unconditionally releases Sharecare Indemnified Parties of
            all liability), and (c) gives Health Security Customer all reasonable assistance, at
            Health Security Customer’s expense. The failure to comply with clause (a) of the
            immediately preceding sentence shall not affect Health Security Customer’s obligation to
            provide indemnification pursuant to this Section except to the extent such failure has
            materially adversely affected its ability to defend such Claim Against Sharecare, and
            notwithstanding clause (b) of the immediately preceding sentence, Sharecare shall have
            the right, at its own expense, to employ separate counsel in any such action, to observe
            the proceedings and, at Sharecare’s request, Health Security Customer will keep such
            counsel reasonably informed of such proceedings.
          </p>
        </li>
        <li className="space-y-6">
          <h5 className="font-bold">14. Limitations of Liability.</h5>
          <p className="pl-2">
            a.
            <strong>{` EXCLUSION OF DAMAGES. `}</strong>
            EXCEPT AS SPECIFIED IN AN ORDER, UNDER NO CIRCUMSTANCES WHATSOEVER SHALL EITHER PARTY BE
            LIABLE TO THE OTHER FOR ANY SPECIAL, CONSEQUENTIAL, PUNITIVE, INDIRECT, OR INCIDENTAL
            DAMAGES OF ANY KIND WHATSOEVER.
          </p>
          <p className="pl-2">
            b.
            <strong>{` LIMIT OF DAMAGE AMOUNTS. `}</strong>
            EXCEPT FOR THEIR RESPECTIVE INDEMNIFICATION OBLIGATIONS HEREUNDER OR AS SPECIFIED IN AN
            ORDER, IN NO EVENT WHATSOEVER SHALL EITHER PARTY’S TOTAL LIABILITY TO THE OTHER, FOR ANY
            OTHER DAMAGES WHATSOEVER, EXCEED THREE (3) TIMES THE SUM OF THE AMOUNTS PAID BY Health
            Security Customer FOR THE SERVICES PROVIDED IN THE TWELVE (12) MONTHS PRECEDING THE
            INCIDENT GIVING RISE TO THE CLAIM. THE FOREGOING LIMITATION WILL NOT LIMIT HEALTH
            SECURITY CUSTOMER’S PAYMENT OBLIGATIONS FOR AMOUNTS DUE UNDER AN ORDER.
          </p>
          <p className="pl-2">
            c.
            <strong>{` EXCEPTIONS TO LIMIATIONS OF LIABILITY. `}</strong>
            EXCEPT AS SPECIFIED IN AN ORDER, THE LIMITATIONS OF LIABILTY IN SECTION 14(b), SHALL NOT
            APPLY TO LIABILITY ARISING FROM THIRD-PARTY INTELLECTUAL PROPERTY CLAIMS, GROSS
            NEGLIGENCE, WILLFUL MISCONDUCT, OR VIOLATIONS OF LAW.
          </p>
        </li>
        <li>
          <h5 className="font-bold">15. Relationship of the Parties.</h5>
          <p>
            It is mutually understood and agreed that the relationship between the Parties shall be
            that of independent entities contracting with each other at arm’s length. This Agreement
            does not, and shall not be construed to, create the relationship of agent, employee,
            partnership, joint venture or association between the Parties.
          </p>
        </li>
        <li>
          <h5 className="font-bold">16. Assignment.</h5>
          <p>
            Upon advance written notice to the other, either Party may assign the Agreement to a
            parent, subsidiary or successor to the business related to the Agreement. This Agreement
            may not be otherwise assigned or transferred by either Party without the express written
            consent of the other Party, which shall not be unreasonably withheld.
          </p>
        </li>
        <li>
          <h5 className="font-bold">17. Subcontractors.</h5>
          <p>
            Sharecare may subcontract to third parties certain duties or obligations under this
            Agreement and shall remain fully responsible to Health Security Customer for all such
            duties or obligations.
          </p>
        </li>
        <li>
          <h5 className="font-bold">18. Publicity.</h5>
          <p>
            Health Security Customer agrees that it will not, without the written consent of
            Sharecare, (i) use in advertising or for other publicity purposes (including, without
            limitation, on the Internet) Sharecare’s name, domain name, any trademark, trade name,
            symbol or any abbreviation or contraction thereof owned by or referring to Sharecare; or
            (ii) represent, directly or indirectly, that any product or service offered Health
            Security Customer has been approved or endorsed by Sharecare unless expressly authorized
            by Sharecare as part of the health security verification. Health Security Customer
            agrees that Sharecare and Forbes Travel Guide may use and publish Health Security
            Customer’s name, logos, trademarks, and other branding, in their promotional information
            relating to the Health Security Product, including, but not limited to, customer lists,
            sales materials, press releases, brochures, reports, letters, white papers, case
            studies, investor materials, in print, electronic, and other media, such as e-mail or
            web pages.
          </p>
        </li>
        <li>
          <h5 className="font-bold">19. Governing Law/Jurisdiction and Venue.</h5>
          <p>
            This Agreement shall be exclusively governed by and construed under the laws of the
            state of Delaware, United States, without regard to its conflict of laws provisions, and
            venue for all actions arising out of this Agreement shall be Delaware, United States,
            without regard to the doctrine of forum non-conveniens.
          </p>
        </li>
        <li>
          <h5 className="font-bold">20. Approval Signatures.</h5>
          <p>
            Signature by authorized representatives of the respective Parties constitutes acceptance
            of and notice to proceed with the performance and provision of the Services. No
            additional work relating to any other project or engagement, or any other part or phase
            of the project than that described in the Agreement, shall be authorized without the
            express written agreement of both Parties hereto.
          </p>
        </li>
        <li>
          <h5 className="font-bold">21. Notices.</h5>
          <p>
            All notices required by either Party under the Agreement shall be made in writing, and
            shall be deemed to have been given on the date such noticed is presented personally, or
            transmitted by facsimile (receipt confirmed), two (2) business days after delivery by a
            nationally recognized courier service, or three (3) days after mailed registered or
            certified, return receipt requested, to Sharecare at 255 E. Paces Ferry Rd. NE, Suite
            700, Atlanta, Georgia, 30305, Attn: Legal, and to Health Security Customer at the
            address provided in the Order, or to such other address as a Party may designate by
            written notice to the other Party provided in accordance with this Section.
          </p>
        </li>
        <li>
          <h5 className="font-bold">22. Non-Waiver.</h5>
          <p>
            The failure of either Party to this Agreement to exercise any of its rights under this
            Agreement at any time does not constitute a breach of this Agreement and shall not be
            deemed to be a waiver of such rights or a waiver of any subsequent breach.
          </p>
        </li>
        <li>
          <h5 className="font-bold">23. Force Majeure.</h5>
          <p>
            Except for payment obligations, a Party shall be excused from the performance of its
            obligations hereunder, and such Party’s nonperformance shall not be a default or grounds
            for termination of this Agreement, to the extent that such Party is prevented from
            performing its obligations as a result any other cause beyond the affected Party’s
            reasonable control.
          </p>
        </li>
        <li>
          <h5 className="font-bold">24. No Third-Party Beneficiaries.</h5>
          <p>
            No person or entity, other than Health Security Customer and Sharecare, is intended to
            be, or is in fact, a beneficiary of this Agreement, and the existence of the Agreement
            shall not in any respect whatsoever increase the right of any member or other third
            party, or create any right on behalf of any member or other third party vis-à-vis any of
            the Parties with respect to the subject matter hereof.
          </p>
        </li>
        <li>
          <h5 className="font-bold">25. Compliance with Laws.</h5>
          <p>
            Each Party represents that it has complied and will continue to comply with all relevant
            federal, state and local laws and regulations.
          </p>
        </li>
        <li>
          <h5 className="font-bold">26. Severability.</h5>
          <p>
            If any part of this Agreement is held to be unenforceable, the rest of this Agreement
            shall nevertheless remain in full force and effect.
          </p>
        </li>
        <li>
          <h5 className="font-bold">27. Survival.</h5>
          <p>
            All provisions which contemplate performance or observance subsequent to any termination
            or expiration of the Agreement or which must survive in order to give effect to its
            meaning, shall survive the expiration or termination of the Agreement.
          </p>
        </li>
        <li>
          <h5 className="font-bold">28. Electronic Agreement.</h5>
          <p>
            The Parties agree to contract electronically. Any requirement of a writing or written
            agreement can be satisfied by electronic means.
          </p>
        </li>
        <li>
          <h5 className="font-bold">29. Equal Opportunity and Compliance with Employment Laws.</h5>
          <p>
            Sharecare warrants that in providing the goods and/or Services specified herein, it will
            comply with the following laws, executive order, and the regulations promulgated
            thereunder, as the same may be amended, when applicable: (A) the Vietnam Era Veterans
            Readjustment Assistance Act of 1974, (B) Executive Order 11246, and (C) the
            Rehabilitation Act of 1973. Any clause required to be set forth in a document of this
            type by such laws, administrative regulations or executive orders shall be deemed to be
            incorporated herein by this reference. Sharecare shall not discriminate against any
            employee or applicant for employment because of race, color, religion, sex, national
            origin, disability, or protected veteran status. If applicable to the Services
            hereunder, Sharecare and any permitted subcontractor shall abide by the requirements of
            41 CFR 60-300.5(a). This regulation prohibits discrimination against qualified protected
            veterans, and requires affirmative action by covered prime contractors and
            subcontractors to employ and advance in employment qualified protected veterans. If
            applicable, Sharecare and any permitted subcontractor shall also abide by the
            requirements of 41 CFR 60-741.5(a). This regulation prohibits discrimination against
            qualified individuals on the basis of disability, and requires affirmative action by
            covered prime contractors and subcontractors to employ and advance in employment
            qualified individuals with disabilities.
          </p>
        </li>
        <li>
          <h5 className="font-bold">30. Entire Agreement.</h5>
          <p>
            These terms and conditions contained in this Agreement constitute the entire agreement
            between the parties with respect to the subject matter hereof; all prior agreements,
            representations, statements, negotiations and undertakings, whether written or oral, are
            superseded hereby. This Agreement may be supplemented, amended or revised only by a
            writing that is signed by each of the Parties.
          </p>
        </li>
      </ul>
    </Page.Main>
  </Page>
);

export default Legal;
