import React from 'react';

import { Default, Greenshield } from 'src/components/legals';

const Legal = () => {
  switch (process.env.GATSBY_VERTICAL) {
    case 'GREENSHIELD':
      return <Greenshield />;
    default:
      return <Default />;
  }
};

export default Legal;
